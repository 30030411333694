import React from "react";
import { Breadcrumbs, Button, Link, useMediaQuery, useTheme } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { HeadingTen } from "./constants";

const styles = {
    mainHeading: {
        fontSize: "2rem",
        fontWeight: "700"
    },
    para: {
        marginTop: "1rem"
    },
    subHeading: {
        fontSize: "1.5rem",
        marginTop: "2rem",
        fontWeight: "700"
    },
    orderedList: {
        margin: "1rem"
    },
    unorderedList: {
        margin: "1rem",
    },
    listHeading: {
        marginTop: "1rem"
    }
};

export default function BlogTen(props) {
    const { setBlogPage, setBlogOne } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleBack = () => {
        setBlogPage(true);
        setBlogOne(false);
    }

    return (
        <React.Fragment>
            <div style={{ margin: "2% 2% 0% 2%" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="inherit" href="/blog">
                        Blogs
                    </Link>
                </Breadcrumbs>
            </div>
            <div style={{ width: "90%", margin: "3%" }}>
                <h1>{HeadingTen}</h1>
                <br />
                <h3>Your Journey to a Rewarding Career in Tech with <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a></h3>
                <p>The tech industry is rapidly evolving, and DevOps has emerged as one of the most sought-after career paths for freshers. If you’re transitioning from college to the professional world, stepping into DevOps and Cloud technologies can be your golden ticket to a high-growth career. Let <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a> guide you on this transformative journey.</p>
                <br/>
                <h3>Why Choose DevOps and Cloud as a Fresher?</h3>
                
                <ul>
                    <li><b>High Demand in the Industry:</b> Companies worldwide are adopting DevOps practices to streamline operations and enhance software delivery. Skills in Cloud platforms like AWS, Azure, and GCP are the backbone of modern tech infrastructure.</li>
                    <li><b>Lucrative Career Opportunities:</b> DevOps professionals and Cloud specialists are among the highest-paid tech roles globally, with significant opportunities for growth.</li>
                    <li><b>Skill Versatility:</b> DevOps combines software development and IT operations, making you a versatile asset. Add Cloud expertise to the mix, and you become indispensable.</li>
                </ul>
                <br/>
                <h3>The <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a> Advantage</h3>
                
                <p>At <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a>, we’ve designed our DevOps and Cloud Master Job-Ready Program to cater specifically to freshers. Here’s how we help you succeed:</p>
                <ul>
                    <li><b>Comprehensive Curriculum:</b>
                        <ul>
                            <li>Learn core DevOps tools like Docker, Kubernetes, Jenkins, and Git.</li>
                            <li>Master Cloud platforms such as AWS, Microsoft Azure, and Google Cloud Platform (GCP).</li>
                            <li>Gain hands-on experience with CI/CD pipelines and Infrastructure as Code (IaC).</li>
                        </ul>
                    </li>
                    <li><b>Practical Learning:</b>
                        <ul>
                            <li>Work on live projects to apply what you learn.</li>
                            <li>Get exposure to real-world scenarios to prepare for industry challenges.</li>
                        </ul>
                    </li>
                    <li><b>Expert Mentorship:</b>
                        <ul>
                            <li>Learn from seasoned industry experts with years of experience in DevOps and Cloud.</li>
                            <li>Receive one-on-one guidance to tackle technical and career-related challenges.</li>
                        </ul>
                    </li>
                    <li><b>Job-Ready Program:</b>
                        <ul>
                            <li>Mock interviews and resume-building sessions.</li>
                            <li>Dedicated placement support with connections to top tech companies.</li>
                        </ul>
                    </li>
                </ul>
                <br/>
                <h3>Success Stories</h3>
                <p>Hundreds of students have transitioned from college to successful careers in DevOps and Cloud with <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a>. Anil Srivastava and Aditya Sharma are just a few examples of our alumni now working at top firms like Oracle and Microsoft.</p>
                <br/>
                <h3>Step-by-Step Roadmap for Freshers</h3>
                <ol>
                    <li><b>Understand the Basics:</b> Start with foundational knowledge in programming and Linux. Learn about DevOps practices and Cloud Computing concepts.</li>
                    <li><b>Enroll in the Right Course:</b> <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a>’s DevOps and Cloud Master Program ensures you get a complete package—from theory to practical knowledge.</li>
                    <li><b>Hands-On Practice:</b> Work with tools like Docker for containerization, Kubernetes for orchestration, and Jenkins for automation. Explore cloud platforms and build infrastructure in AWS, Azure, and GCP.</li>
                    <li><b>Build Your Portfolio:</b> Create and showcase projects that demonstrate your expertise to potential employers.</li>
                    <li><b>Ace Interviews with Confidence:</b> Utilize <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a>’s placement support to prepare for technical and HR interviews.</li>
                </ol>
                <br/>
                <h3>Why <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a> Stands Out</h3>
                <ul>
                    <li><b>Global Presence:</b> With students in over 20 countries, <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a> is recognized as a leader in DevOps and Cloud training.</li>
                    <li><b>Award-Winning Growth:</b> Ranked among Deloitte’s fastest-growing companies, we deliver education that makes an impact.</li>
                    <li><b>Trusted by Top Companies:</b> Collaborations with IBM, TCS, and more ensure that our students receive the best opportunities.</li>
                </ul>
                <br/>
                <h3>Secure Your Future with <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a></h3>
                <p>Are you ready to transition from college to a thriving DevOps career? Join <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a>’s DevOps and Cloud Master Job-Ready Program today. Take the first step toward becoming an in-demand professional with industry-relevant skills, hands-on experience, and placement support.</p>

                <p>Let <a style={{color:"#1F509A", textDecoration: "underline"}} href="https://paperlive.in/devops-training-online">Paperlive Learning</a> be your partner in this journey. Your dream career in DevOps and Cloud awaits!</p>

            </div>

        </React.Fragment>
    );
}