import React, { useEffect } from 'react';

const MetaWrapper = ({ title, description, children }) => {
  useEffect(() => {
    document.title = title;

    const metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = description;
      document.head.appendChild(meta);
    }
  }, [title, description]);

  return <>{children}</>;
};

export default MetaWrapper;
