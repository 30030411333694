import React, { useEffect, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DotLoader } from 'react-spinners';
import Chatbot from './Footer/Chatbot';
import RefundPolicy from './TermsAndCondition/RefundPolicy';
import About from './About/About';
import Privacy from './Privacy/Privacy';
import AWSMain from './AWS/AWSMain';
import BlogOne from './Blogs/BlogOne';
import BlogTwo from './Blogs/BlogTwo';
import BlogThree from './Blogs/BlogThree';
import BlogFour from './Blogs/BlogFour';
import BlogSix from './Blogs/BlogSix';
import BlogSeven from './Blogs/BlogSeven';
import BlogEight from './Blogs/BlogEight';
import BlogNine from './Blogs/BlogNine';
import AzureMain from './Azure/AzureMain';
import GCPMain from './GCP/GCPMain';
import MetaWrapper from './MetaWrapper';
import DevopsMain from './DevOps/DevopsMain';
import BlogTen from './Blogs/BlogTen';
import BlogEleven from './Blogs/BlogEleven';

// Lazy load the components
const CloudCourseMain = React.lazy(() => import('./components/CloudCourseMain'));
const LandingPage = React.lazy(() => import('./LandingPageComponents/LandingPage'));
const AllFooter = React.lazy(() => import('./Footer/AllFooter'));
const AllHeader = React.lazy(() => import('./Header/AllHeader'));
const StickyFooter = React.lazy(() => import('./Footer/StickyFooter'));
const BlogsPage = React.lazy(() => import('./Blogs/BlogsPage'));
const TermsAndCondition = React.lazy(() => import('./TermsAndCondition/TermsAndCondition'));
const ThankyouPage = React.lazy(() => import('./ThankyouComponent/ThankyouPage'));

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-N1CE0V9ZXD', {
        page_path: location.pathname,
      });
      window.gtag('config', 'AW-16687929732', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <div className="container">
      <Helmet>
        {/* Google Tag Manager */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MMQ2GZXH');
          `}
        </script>

        {/* Google Analytics (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1CE0V9ZXD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-N1CE0V9ZXD', {
              page_path: window.location.pathname,
            });
            gtag('config', 'AW-16687929732', {
              page_path: window.location.pathname,
            });
          `}
        </script>

        {/* Microsoft Clarity Script */}
        <script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "o598xd72w1");
          `}
        </script>

        {/* Facebook Pixel Script */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '587545047035951'); 
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>

      {/* Render noscript with dangerouslySetInnerHTML */}
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <!-- Google Tag c (noscript) -->
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMQ2GZXH"
            height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
            <!-- End Google Tag Manager (noscript) -->
            <!-- Facebook Pixel (noscript) -->
            <noscript>
              <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=587545047035951&ev=PageView&noscript=1" />
            </noscript>
            <!-- End Facebook Pixel (noscript) -->
          `,
        }}
      />

      <Suspense fallback={<div style={{ margin: "20% auto 50% auto", display: "table" }}><DotLoader color="#8f128e" loading /></div>}>
        <AllHeader />
        <StickyFooter />
        <Routes>
          <Route
            path="/"
            element={
              <MetaWrapper title="PaperLive Learning | Online Training Courses" description="Join our Online Training to gain hands-on experience with real-time projects.">
                <LandingPage />
              </MetaWrapper>
            }
          />
          <Route path="/devops-training-online" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-hyderabad" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Hyderabad"} />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-bangalore" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Bangalore"} />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-pune" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Pune"} />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-chennai" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Chennai"} />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-mumbai" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Mumbai"} />
            </MetaWrapper>
          } />
          <Route path="/devops-training-online-delhi" element={
            <MetaWrapper title="DevOps and Cloud Job Ready Program | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <CloudCourseMain city={"Delhi"} />
            </MetaWrapper>
          } />

          <Route path="/devops-certification-training" element={
            <MetaWrapper title="Devops Certification Training | PaperLive Learning" description="Master DevOps with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <DevopsMain />
            </MetaWrapper>
          } />

          <Route path="/aws-solution-architect" element={
            <MetaWrapper title="AWS Solution Architect Program | PaperLive Learning" description="Master AWS with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <AWSMain />
            </MetaWrapper>
          } />
          <Route path="/microsoft-azure" element={
            <MetaWrapper title="Microsoft Azure Program | PaperLive Learning" description="Master Microsoft Azure with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <AzureMain />
            </MetaWrapper>
          } />
          <Route path="/google-cloud-platform" element={
            <MetaWrapper title="Google Cloud Platform Program | PaperLive Learning" description="Master Google Cloud Platform with hands-on training! With real-time projects for practical experience. Train with industry experts, work on live projects, and gain the skills top companies demand.">
              <GCPMain />
            </MetaWrapper>
          } />
          <Route path="/blog" element={
            <MetaWrapper title="Blog | PaperLive Learning">
              <BlogsPage />
            </MetaWrapper>
          } />
          <Route path="/terms" element={
            <MetaWrapper title="Terms and Conditions | PaperLive Learning">
              <TermsAndCondition />
            </MetaWrapper>
          } />
          <Route path="/refund-policy" element={
            <MetaWrapper title="Refund Policy | PaperLive Learning">
              <RefundPolicy />
            </MetaWrapper>
          } />
          <Route path="/thankyou" element={
            <MetaWrapper title="Thankyou | PaperLive Learning">
              <ThankyouPage />
            </MetaWrapper>
          } />
          <Route path="/about-us" element={
            <MetaWrapper title="About Us | PaperLive Learning" >
              <About />
            </MetaWrapper>
          } />
          <Route path="/privacy-policy" element={
            <MetaWrapper title="Privacy Policy | PaperLive Learning" >
              <Privacy />
            </MetaWrapper>
          } />

          {/* Blogs */}
          <Route path="/blog/what-is-devOps-deployment-and-how-does-it-function-in-devops" element={
            <MetaWrapper title="What is DevOps Deployment and How Does It Function in DevOps?" >
              <BlogOne />
            </MetaWrapper>
          } />
          <Route path="/blog/over-60+-azure-devops-interview-questions-and-answers-to-prepare-for-2024" element={
            <MetaWrapper title="Over 60+ Azure DevOps Interview Questions and Answers to Prepare for 2024" >
              <BlogTwo />
            </MetaWrapper>
          } />
          <Route path="/blog/cloud-computing-advantages-and-challenges-of-transitioning-to-cloud-services" element={
            <MetaWrapper title="Cloud Computing: Advantages and Challenges of Transitioning to Cloud Services?" >
              <BlogThree />
            </MetaWrapper>
          } />
          <Route path="/blog/what-is-aws-redshift" element={
            <MetaWrapper title="What is AWS Redshift?" >
              <BlogFour />
            </MetaWrapper>
          } />
          <Route path="/blog/docker-in-devops-what-is-it-and-how-does-it-operate" element={
            <MetaWrapper title="Docker in DevOps: What Is It and How Does It Operate?" >
              <BlogSix />
            </MetaWrapper>
          } />
          <Route path="/blog/exploring-the-convergence-of-ai-and-devops-engineering-roles" element={
            <MetaWrapper title="Exploring the Convergence of AI and DevOps Engineering Roles" >
              <BlogSeven />
            </MetaWrapper>
          } />
          <Route path="/blog/what-is-cloud-consulting-and-what-advantages-does-it-offer-to-businesses" element={
            <MetaWrapper title="What is cloud consulting and what advantages does it offer to businesses?" >
              <BlogEight />
            </MetaWrapper>
          } />
          <Route path="/blog/choosing-the-right-cloud-strategy-for-your-business-multi-cloud-vs-hybrid-cloud" element={
            <MetaWrapper title="Choosing the Right Cloud Strategy for Your Business: Multi-Cloud vs. Hybrid Cloud" >
              <BlogNine />
            </MetaWrapper>
          } />
          <Route path="/blog/from-college-to-devops-a-roadmap-for-freshers" element={
            <MetaWrapper title="From College to DevOps: A Roadmap for Freshers" >
              <BlogTen />
            </MetaWrapper>
          } />
          <Route path="/blog/the-role-of-cloud-computing-in-devops-a-beginners-guide" element={
            <MetaWrapper title="The Role of Cloud Computing in DevOps: A Beginner’s Guide" >
              <BlogEleven />
            </MetaWrapper>
          } />
        </Routes>
        {/* <Chatbot /> */}
        <AllFooter />
      </Suspense>
    </div>
  );
}

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
